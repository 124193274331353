<template>
    <v-container v-if="liegenschaft" fluid>
        <v-row dense>
            <v-col sm="8" cols="8">
                <kp-textfield
                    v-model="liegenschaft.strasse"
                    autocomplete="new-password"
                    :label="$t('strasse') + ' *'"
                    :readonly="readOnlyCalc"
                    :rules="[rules.required, rules.maxLength(100), rules.charsOnly]"
                    @change="clearEgid"
                    @blur="() => (liegenschaft.strasse = modStreet(liegenschaft.strasse))"
                />
            </v-col>
            <v-col sm="4" cols="4">
                <kp-textfield
                    v-model="liegenschaft.strassen_nummer"
                    autocomplete="new-password"
                    :label="$t('nr')"
                    :readonly="readOnlyCalc"
                    :rules="[rules.maxLength(20), rules.houseNumber]"
                    @change="clearEgid"
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" sm="6">
                <OrtSelect
                    v-model="liegenschaft.ort_id"
                    :label="$t('plz') + '/' + $t('ort') + ' *'"
                    :read-only="readOnlyCalc"
                    :rules="[rules.required]"
                    @input="onOrtIdChange"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <GemeindeSelect
                    v-model="liegenschaft.gemeinde_id"
                    :ort-id="liegenschaft.ort_id"
                    :label="$t('gemeinde') + ' *'"
                    :read-only="readOnlyCalc"
                    :rules="[rules.required]"
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <v-text-field
                        v-model="liegenschaft.egid"
                        :readonly="true"
                        :clearable="!readOnly"
                        clear-icon="fas fa-times"
                        :label="$t('egid')"
                        :loading="egidLoading"
                    >
                        <template v-if="addressComplete && !readOnlyCalc" v-slot:append>
                            <v-tooltip v-model="showEgidTooltip" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        v-bind="attrs"
                                        :disabled="egidLoading"
                                        rounded
                                        small
                                        class="mb-1"
                                        v-on="on"
                                        @click="egidLookup"
                                    >
                                        {{ $t('egid_assign') }}
                                    </v-btn>
                                </template>
                                <span>{{ $t('egid_find_and_assign') }}</span>
                            </v-tooltip>
                        </template>
                    </v-text-field>
                    <field-info-icon :infotext="infotext('egid')" left />
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <v-checkbox v-model="liegenschaft.ist_neubau" :readonly="readOnlyCalc" :label="$t('neubau')" />
                    <field-info-icon :infotext="infotext('ist_neubau')" left />
                </div>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <v-text-field
                        :value="liegenschaft.baujahr"
                        :label="$t('baujahr') + (liegenschaft.ist_neubau ? '' : ' *')"
                        :readonly="readOnlyCalc"
                        :rules="liegenschaft.ist_neubau ? [] : [rules.between(1000, 2100), rules.required]"
                        type="number"
                        @input="(v) => (liegenschaft.baujahr = v ? parseInt(v) : null)"
                    />
                    <field-info-icon :infotext="infotext('baujahr')" left />
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <v-checkbox
                        v-model="liegenschaft.baujahr_ist_geschaetzt"
                        :label="$t('baujahr_geschaetzt')"
                        :readonly="readOnlyCalc"
                    />
                    <field-info-icon :infotext="infotext('baujahr_ist_geschaetzt')" left />
                </div>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <kp-textfield
                        v-model="liegenschaft.parzellen_nummer"
                        :readonly="readOnlyCalc"
                        :label="$t('parz_nr')"
                    />
                    <field-info-icon :infotext="infotext('parzellen_nummer')" left />
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <div class="d-flex">
                    <kp-textfield
                        v-model="liegenschaft.assekuranz_nummer"
                        :readonly="readOnlyCalc"
                        :label="$t('assek_nr')"
                    />
                    <field-info-icon :infotext="infotext('assekuranz_nummer')" left />
                </div>
            </v-col>
        </v-row>
        <ApplyDlg v-model="applyDlg" :changes="gwrdata" @apply="applyGwrData" />
    </v-container>
</template>

<script>
import OrtSelect from 'js/components/ort-select.vue';
import GemeindeSelect from 'js/components/gemeinde-by-ort-select.vue';
import ApplyDlg from 'js/components/liegenschaft-apply-dlg.vue';
import FieldInfoIcon from 'js/components/field-info-icon.vue';
import { mapActions, mapState } from 'vuex';
import { errToStr, completeStreetString } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        OrtSelect,
        ApplyDlg,
        GemeindeSelect,
        'field-info-icon': FieldInfoIcon,
        KpTextfield
    },
    props: {
        liegenschaft: {
            type: Object,
            require: true,
            default: null
        },
        rules: {
            type: Object,
            require: true,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        felderConfig: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            gwrdata: [],
            modStreet: completeStreetString,
            applyDlg: false,
            egidLoading: false,
            showEgidTooltip: false
        };
    },
    computed: {
        ...mapState('benutzer', {
            benutzer: 'record'
        }),
        addressComplete() {
            return this.liegenschaft && this.liegenschaft.ort_id > 0 && (this.liegenschaft.strasse || '').length > 0;
        },
        readOnlyCalc() {
            if (this.$store.getters.isAdmin) {
                return false;
            }
            if (this.liegenschaft.ersteller_id) {
                return this.readOnly || this.liegenschaft.ersteller_id !== this.benutzer.id;
            }
            return this.readOnly;
        }
    },
    methods: {
        ...mapActions(['runAsync']),
        async egidLookup() {
            this.showEgidTooltip = false;
            if (!this.liegenschaft.ort_id || !this.liegenschaft.gemeinde_id || !this.liegenschaft.strasse) {
                this.$toast({ message: this.$t('address_please'), color: 'error' });
                return;
            }
            try {
                this.egidLoading = true;
                let egid = await this.runAsync(() => this.liegenschaft.egidQuery());
                if (egid && egid.egid) {
                    this.liegenschaft.egid = egid.egid;
                    this.liegenschaft.coord_x = egid.x;
                    this.liegenschaft.coord_y = egid.y;
                    this.gwrdata = [];
                    if (this.showGWRValue(egid.strasse, this.liegenschaft.strasse)) {
                        this.gwrdata.push({
                            key: 'strasse',
                            gwrValue: egid.strasse,
                            value: this.liegenschaft.strasse
                        });
                    } else {
                        this.liegenschaft.strasse = egid.strasse;
                    }
                    if (this.showGWRValue(egid.strassen_nummer, this.liegenschaft.strassen_nummer)) {
                        this.gwrdata.push({
                            key: 'strassen_nummer',
                            gwrValue: egid.strassen_nummer,
                            value: this.liegenschaft.strassen_nummer
                        });
                    } else {
                        this.liegenschaft.strassen_nummer = egid.strassen_nummer;
                    }
                    if (this.showGWRValue(egid.baujahr, this.liegenschaft.baujahr)) {
                        this.gwrdata.push({
                            key: 'baujahr',
                            gwrValue: egid.baujahr,
                            value: this.liegenschaft.baujahr
                        });
                    } else {
                        this.liegenschaft.baujahr = egid.baujahr;
                    }
                    if (this.showGWRValue(egid.parzelleNr, this.liegenschaft.parzellen_nummer)) {
                        this.gwrdata.push({
                            key: 'parzellen_nummer',
                            gwrValue: egid.parzelleNr,
                            value: this.liegenschaft.parzellen_nummer
                        });
                    } else {
                        this.liegenschaft.parzellen_nummer = egid.parzelleNr;
                    }
                    if (this.showGWRValue(egid.assekuranz_nummer, this.liegenschaft.assekuranz_nummer)) {
                        this.gwrdata.push({
                            key: 'assekuranz_nummer',
                            gwrValue: egid.assekuranz_nummer,
                            value: this.liegenschaft.assekuranz_nummer
                        });
                    } else {
                        this.liegenschaft.assekuranz_nummer = egid.assekuranz_nummer;
                    }
                    this.applyDlg = this.gwrdata.length > 0;
                    if (this.gwrdata.length === 0) {
                        this.$toast({ message: this.$t('check_gwr_data'), color: 'warning', timeout: 10000 });
                    }
                } else {
                    this.liegenschaft.egid = null;
                    this.$toast({ message: this.$t('no_egid_found'), color: 'warning', timeout: 0 });
                }
            } catch (e) {
                this.liegenschaft.egid = null;
                this.$toast({ message: errToStr(e), color: 'error' });
            } finally {
                this.egidLoading = false;
            }
        },
        showGWRValue(gwrValue, actualValue) {
            return actualValue && actualValue !== gwrValue;
        },

        onOrtIdChange() {
            this.clearEgid();
            // clear Gemeinde-ID, User must select again
            this.liegenschaft.gemeinde_id = null;
        },

        applyGwrData(data) {
            this.applyDlg = false;
            data.forEach((d) => (this.liegenschaft[d.key] = d.value));
        },

        infotext(fieldIdent) {
            if (this.felderConfig && this.felderConfig.felder && this.felderConfig.felder[fieldIdent]) {
                return this.felderConfig.felder[fieldIdent].hilfetext || null;
            }
            return null;
        },

        clearEgid() {
            this.liegenschaft.egid = null;
            this.liegenschaft.baujahr = null;
            this.liegenschaft.parzellen_nummer = null;
            this.liegenschaft.assekuranz_nummer = null;
            this.liegenschaft.coord_x = null;
            this.liegenschaft.coord_y = null;
        }
    }
};
</script>
